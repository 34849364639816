$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

//Fix for cursor on steps
.p-steps .p-steps-item .p-menuitem-link {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.text-uppercase {
  text-transform: uppercase !important;
}

// OFFERS, ORDERS, DELIVERY NOTES
.narrow-table {
  .p-datatable {
    .p-datatable-thead > tr > th {
      padding: 0.3rem;
    }

    .p-datatable-tbody > tr > td {
      padding: .2rem .4rem;

      .table-button {
        padding: .2rem;
      }
    }
  }

  .p-paginator {
    padding: 0.3rem !important;
  }
}

.sticky-div-top {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* The div will stick to the top of the page as you scroll */
  background: white; /* Keep the background color for consistency */
  z-index: 10; /* Ensure it is above other content */
}

.sticky-div-bottom {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  bottom: 0; /* The div will stick to the top of the page as you scroll */
  background: white; /* Keep the background color for consistency */
  z-index: 10; /* Ensure it is above other content */
}

.grey-background-modal .p-dialog-content,
.grey-background-modal .p-dialog-header {
  background-color: #F8F9FA;
}

.required-mark {
  &::after {
    content: '*';
    display: inline-block;
    color: #ff3d3d;
    margin-left: .2em;
  }
}

/* forms */
 label.required-mark {
   &::after {
     content: '*';
     display: inline-block;
     color: #ff3d3d;
     margin-left: .2em;
   }
 }
